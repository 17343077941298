export enum CountryViewColors {
    'NoData' = 'rgba(216, 232, 236, 1)',
    '<10' = 'rgba(136, 208, 235, 1)',
    '10-100' = 'rgba(100, 198, 240, 1)',
    '101-500' = 'rgba(41, 177, 234, 1)',
    '501-2,000' = 'rgba(0, 147, 228, 1)',
    '2,001-5,000' = 'rgba(0, 116, 171, 1)',
    '>5,000' = 'rgba(34, 88, 147, 1)',
    'Fallback' = 'rgba(215, 233, 237, 1)',
    'Outline' = 'rgba(0, 148, 226, 1)',
}
